<template>
  <div class="sub-menu">
    <div class="nav-bar">
      <div class="menu-links">
        <div class="menu-link" v-if="getPermissionInterface('Statistique')">
          <router-link class="menu-item" to="/statistique/employeur">
            Employeur
          </router-link>
        </div>
        <div class="menu-link" v-if="getPermissionInterface('Statistique')">
          <router-link class="menu-item" to="/statistique/regie">
            Régie
          </router-link>
        </div>
        <div class="menu-link" v-if="getUserData.role == 'admin_regie'">
          <router-link class="menu-item" to="/statistique/sousregie">
            Sous Régie
          </router-link>
        </div>
      </div>
    </div>
    <div class="body">
      <router-view></router-view>
    </div>
  </div>
</template>

<script>
import { mapGetters } from 'vuex';
export default {
  computed: {
    ...mapGetters(['getUserData', 'getPermissionInterface'])
  }
};
</script>

<style scoped lang="scss"></style>
